// src/context/AuthContext.js
import React, { createContext, useState } from 'react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [mood, setMood] = useState({ backgroundColor: '#090809', color: '#e3e3e3' });

    const login = () => setIsAuthenticated(true);
    const logout = async () => {
        try {
            const response = await fetch('/api/logout', {
                method: 'POST',
                credentials: 'include', // Needed to include the session cookie in the request
            });
    
            if (response.ok) {
                // If the logout was successful on the server, update the local state
                setIsAuthenticated(false);
                
            } else {
                // Handle errors, e.g., show a message to the user
                console.error('Logout failed');
            }
        } catch (error) {
            // Handle network errors
            console.error('Network error on logout:', error);
        }
    };
    
    
    const toggleMood = () => {
        const newMood = mood.backgroundColor === '#090809'
            ? { backgroundColor: '#e3e3e3', color: '#090809' }
            : { backgroundColor: '#090809', color: '#e3e3e3' };
        setMood(newMood);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, mood, toggleMood }}>
            {children}
        </AuthContext.Provider>
    );
};
