import React, { useContext } from 'react';
import Button from './Button';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function Header() {
    const location = useLocation(); // Get the current location
    const pathname = location.pathname; // Get the pathname from location
    const { isAuthenticated, logout, mood, toggleMood } = useContext(AuthContext);

    return (
        <header>
            <h1 className='page-title'>24PS Playground</h1>
            <div className='auth-buttons-div'>
                {isAuthenticated ? (
                    <>
                        <Button name='' displayText='Mood' onClick={toggleMood} />
                        <Button name='' displayText='Logout' onClick={logout} />
                    </>
                ) : (
                    <>
                        {pathname !== '/' && <Button name='' displayText='Back'/>}
                        {pathname === '/' && <Button name='register' displayText='Register' />}
                        {pathname === '/' && <Button name='login' displayText='Login' />}
                    </>
                )}
            </div>
        </header>
    );
}

export default Header;
