import './App.css';
import React, { useContext } from 'react';
import { AuthProvider, AuthContext } from './context/AuthContext';
import Header from './components/Header.js';
import Books from './components/Books.js';
import Register from './components/Register.js';
import Login from './components/Login.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Layout = ({ children }) => {
  const { mood } = useContext(AuthContext); // Use mood from AuthContext

  return (
    <div style={{ background: mood.backgroundColor, color: mood.color, minHeight: '100vh' }}>
      <Header />
      {children}
    </div>
  );
};

function App() {
  return (
    <AuthProvider >
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout><Books /></Layout>} />
          <Route path='register' element={<Layout><Register /></Layout>} />
          <Route path='login' element={<Layout><Login /></Layout>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}


export default App;
