import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Register() {
    // Add any additional client-side validation logic if needed
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Reset error message on new submission

        try {
            const response = await fetch('/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, lastname, email, password }),
            });

            if (response.ok) {
                navigate('/'); // Redirect to root path
            } else {
                // Handle errors
                setErrorMessage('Registration failed. Please retry.');
            }
        } catch (error) {
            // Handle network errors
            setErrorMessage('Network error. Please retry.');
        }
    };

    return (
        <div>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            <form className='form' method='post' onSubmit={handleRegister}>
                <label htmlFor='name' className='form-labels'>Enter your name:</label>
                <input type='text' name='name' id='name' value={name} onChange={(e) => setName(e.target.value)} required />

                <label htmlFor='lastname' className='form-labels'>Enter your lastname:</label>
                <input type='text' name='lastname' id='lastname' value={lastname} onChange={(e) => setLastname(e.target.value)} required />

                <label htmlFor='email' className='form-labels'>Enter your email:</label>
                <input type='email' name='email' id='email' value={email} onChange={(e) => setEmail(e.target.value)} required pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="Invalid email address" />

                <label htmlFor='password' className='form-labels'>Enter your password:</label>
                <input type='password' name='password' id='password-register' value={password} onChange={(e) => setPassword(e.target.value)} required autoComplete="new-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />

                <button type='submit' className='nice-button'>Submit</button>
            </form>
        </div>
    );
};

export default Register;
