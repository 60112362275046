import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

function Login() {
    const { login } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Reset error message on new submission

        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include', // Needed to handle cookies if your server uses session-based auth
            });

            if (response.ok) {
                login(); // Set isAuthenticated to true
                navigate('/'); // Redirect to root path
            } else {
                // Handle errors
                setErrorMessage('Authentication failed. Please retry.');
            }
        } catch (error) {
            // Handle network errors
            setErrorMessage('Network error. Please retry.');
        }
    };

    return (
        <div>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            <form className='form' onSubmit={handleLogin}>
                <label htmlFor='email' className='form-labels'>Enter your email:</label>
                <input type='email' name='email' id='email-login' value={email} onChange={(e) => setEmail(e.target.value)} required />

                <label htmlFor='password' className='form-labels'>Enter your password:</label>
                <input type='password' name='password' id='password-login' value={password} onChange={(e) => setPassword(e.target.value)} required />

                <button type='submit' className='nice-button'>Login</button>
            </form>
        </div>
    );
};

export default Login;
