import React from 'react';
import { useNavigate } from 'react-router-dom';

function Button(props) {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        if (props.onClick) {
            props.onClick(); // Call onClick if it's provided
        } else {
            const path = props.name ? `/${props.name}` : '/';
            navigate(path);
        }
    };
    return (
        <button type='button' name={props.name} className='nice-button' onClick={handleButtonClick}>
            {props.displayText}
        </button>
    );
}

export default Button;
